<template>
  <div>
    <v-row class="mb-4">
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-text-field
            :label="$t('company.search.label')"
            :placeholder="$t('company.search.placeholder')"
            v-model="search.search"
            solo
            clearable
            hide-details
            @click:clear="searchClear"
            @keypress.enter="searchItem"
        ></v-text-field>
      </v-col>
      <v-col class="d-flex ml-md-n5" cols="12" sm="6" md="4" lg="3">
        <v-select
            v-model="search.search_select"
            :items="search_from"
            label="Search from"
            :append-icon="'mdi-chevron-down'"
            solo
            hide-details
            :menu-props="{ bottom: true, offsetY: true }"
        ></v-select>
        <v-btn
            style="padding: 24px 0 25px 0;"
            elevation="2"
            color="primary"
            medium
            class="ml-1"
            @click="searchItem"
        >
          <v-icon size="24">fa-search</v-icon>
        </v-btn>

      </v-col>
    </v-row>
   <v-row>
     <v-col cols="12">
       <div :style="`overflow: auto; position: relative; display: inline-block; width: 100%; max-height: ${height}px;`">
         <table class="mx-table-full" id="companies_table">
           <thead class="">
           <tr class="red">
             <th style="width: 280px; max-width: 280px; min-width: 280px;">
               {{ $t('company.table.company_name') }}
             </th>
             <th style="width: 90px; max-width: 90px; min-width: 90px;">
               {{ $t('company.table.company_name_short') }}
             </th>
             <th style="min-width: 200px; max-width: 200px;">
               {{ $t('company.table.company_phone') }}
             </th>
           </tr>
           </thead>
           <tbody>

           <template v-if="permissionCan('customer-company-show')">
             <router-link tag="tr" :to="{name: 'companies.show', params: {company_id: item.id}}" v-for="(item, index) in items" :key="index" :class="{'active': item.id === item_id || item.is_main === 1}">
               <td>{{ item.company_name }}<small style="margin-top: -2px; display: block; color: #7f7f7f">{{ item.customer.first_name }} {{ item.customer.last_name }}</small></td>
               <td>{{ item.company_name_short }}</td>
               <td>{{ item.company_phone }}</td>
             </router-link>
           </template>

           <template v-else >
             <tr v-for="(item, index) in items" :key="index">
               <td style="cursor: default">{{ item.company_name }}<small style="margin-top: -2px; display: block; color: #7f7f7f">{{ item.customer.first_name }} {{ item.customer.last_name }}</small></td>
               <td style="cursor: default">{{ item.company_name_short }}</td>
               <td style="cursor: default">{{ item.company_phone }}</td>
             </tr>

           </template>



           </tbody>
         </table>
       </div>
       <v-pagination
           v-if="total_pages > 1"
           v-model="page"
           class="my-4"
           :total-visible="7"
           :length="total_pages"
       ></v-pagination>
     </v-col>
   </v-row>

  </div>
</template>

<script>
import {can} from "@/middleware/access_middleware";


export default {
  data() {
    return {
      page: 1,
      meta: 20,
      total_pages: 0,
      isMobile: true,
      height: 0,
      item: {},
      items: [],
      item_id: 1,
      search: {
        search: null,
        search_select: 'company_name'
      },
      search_from: [
        {
          text: this.$t('company.search.select.company_name'),
          value: 'company_name'
        },
        {
          text: this.$t('company.search.select.company_name_short'),
          value: 'company_name_short'
        },
        {
          text: this.$t('company.search.select.company_phone'),
          value: 'company_phone'
        }
      ],
    }
  },
  watch: {
    page() {
      if (this.search.search === null) {
        this.getItems()
      } else {
        this.searchItem()
      }
    },
    'search.search'() {
      if (this.search.search === '') {
        this.search.search = null
        this.getItems()
      }
    }
  },
  created() {
    setTimeout(this.tableHeight, 300)
    this.getItems()
  },
  mounted() {
    this.tableHeight()
    this.onResize()
    window.addEventListener('resize', this.onResize, {passive: true})
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize)
    }
  },
  methods: {
    permissionCan(permission) {
      return can(permission)
    },
    onResize() {
      this.isMobile = window.innerWidth < 600
    },
    tableHeight() {
      this.height = window.innerHeight - (document.getElementById('companies_table').getBoundingClientRect().top + 100)
    },
    getId(id) {
      this.item_id = id
      this.$emit('getId', id)
    },
    searchItem() {
      if (this.search.search !== null) {
        this.$store.dispatch('companySearch', this.search).then(() => {
          this.total_pages = 1
          this.items = this.$store.getters.companies.data
        })
      }
    },
    getItems() {
      this.$store.dispatch('companies', this.page).then(() => {
        this.generateItems()
      })
    },
    generateItems() {
      this.items = this.$store.getters.companies.data
      this.meta = this.$store.getters.companies.meta
      this.page = this.$store.getters.companies.meta.current_page

      this.total_pages = Math.ceil(this.$store.getters.companies.meta.total / 25)
    },
    searchClear() {
      this.getItems()
    }
  }
}
</script>
