<template>
  <div id="lprs" :style="`position: relative; min-height: ${lprs_height}px;`">
    <mx-preloader :z-index="7" :logo="true" :spiner="false" :loader-status="company_id === null || preloader === true"/>
    <v-row>
      <v-col cols="12">
        <v-card>

          <v-tabs :ripple="false" v-model="tab" fixed-tabs>
            <v-tabs-slider :ripple="false" color="primary"></v-tabs-slider>
            <v-tab :disabled="item.disabled" :ripple="false" v-for="item in items" :key="item.id">
              {{ item.name }}
            </v-tab>
          </v-tabs>

          <v-tabs-items id="tbsh" v-model="tab" :style="`height: ${tbsh_height}px;`">
            <v-tab-item class="pa-6" style="height: 100%; overflow-y: auto">
              <v-row style="height: 100%;">
                <template>
                  <v-col cols="12" md="6">
                    <CompanyCard :company="company" />
                  </v-col>
                </template>

                <v-col style="margin-top: auto;" cols="12">
                  <v-card flat style="min-height: 86px;">
                    <v-card-title>
                      <v-icon class="mr-2">mdi-clipboard-account-outline</v-icon>
                      <span style="font-weight: 300;">{{ customer.first_name }} {{ customer.last_name }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-subtitle>
                      <strong>{{ $t('customer.show.gender.title') }}:</strong> <span>{{ $t(`customer.show.gender.${customer.gender}`) }}</span> <br>
                      <strong>{{ $t('customer.show.identification') }}:</strong> <span>{{ customer.identification }}</span> <br>
                      <strong>{{ $t('customer.show.email') }}:</strong> <span><a :href= "`mailto:${customer.email}`">{{ customer.email }}</a></span> <br>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-6" style="height: 100%; overflow-y: auto">
              <v-row style="height: 100%;">
                <template>
                  <v-col cols="12">
                    <TableFullPersons :contact_persons="contact_persons" />
                  </v-col>
                </template>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-6" style="height: 100%; overflow-y: auto">
              <v-row style="height: 100%;">
                <template>
                  <v-col cols="12">
                    <TableFullAddresses :addresses="company_addresses" />
                  </v-col>
                </template>
              </v-row>
            </v-tab-item>

            <v-tab-item class="px-6" style="height: 100%; overflow-y: auto">
              <v-row style="height: 100%;">
                <template>
                  <v-col class="align-center d-flex flex-column" cols="12">
                    <h1 class="mt-16">{{faq.welcome_text }}</h1>
                    <v-btn v-if="questions.length === 0" @click="getQuestions" x-large class="mt-16" color="primary">{{ $t('faq.question.get') }}</v-btn>
                  </v-col>
                </template>
                <template v-if="questions.length > 0">
                  <v-col cols="12">
                    <QuestionsList :questions="questions"/>
                  </v-col>
                </template>
              </v-row>
            </v-tab-item>

          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import CompanyCard from "@/components/Company/CompanyCard";
import TableFullPersons from "@/components/Tables/TableFullPersons";
import TableFullAddresses from "@/components/Tables/TableFullAddresses";
import QuestionsList from "@/components/Faq/QuestionsList";

export default {
  components: { CompanyCard, TableFullPersons, TableFullAddresses, QuestionsList },
  props: {
    company_id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      page: 1,
      preloader: true,
      tbsh_height: 0,
      lprs_height: 0,
      customer: {},
      tab: null,
      // items: [
      //   'Firmen',
      //   'Mitarbeiter',
      //   'Standorte',
      //   'FAQ',
      // ],
      items: [
        { id: 1, name: this.$t('tabs.companies'), disabled: false},
        { id: 2, name: this.$t('tabs.employee'), disabled: false},
        { id: 3, name: this.$t('tabs.address'), disabled: false},
        { id: 4, name: this.$t('tabs.faq'), disabled: false},
      ],
      company: {},
      faq: [],
      questions: [],
      contact_persons: [],
      company_addresses: [],
      company_load: false
    }
  },
  created() {
    setTimeout(this.MxHeight, 1)
    setTimeout(this.mxLoader, 300)
  },
  watch: {
    company_id() {
      this.getCompany()
    }
  },
  mounted() {
    this.MxHeight()
    this.getCompany()
  },
  methods: {
    getCompany() {
      if (this.company_id !== null) {
        this.$store.dispatch('companyShow', this.company_id).then(() => {
          this.company = this.$store.getters.company
          this.customer = this.$store.getters.company.customer
          this.faq = this.$store.getters.company.faq
          this.contact_persons = this.$store.getters.company.persons
          this.company_addresses = this.$store.getters.company.addresses

          if (this.customer.can_faq === 1) {
            this.items[3].disabled = false
          } else {
            if (this.tab === 3) {
              this.tab = 0
            }
            this.items[3].disabled = true
          }

        })
      }
    },
    getQuestions() {
      this.$store.dispatch('questionPerFaqId', this.company.faq.id).then(() => {
        this.questions = this.$store.getters.company.faq.questions
      })
    },
    mxLoader() {
      this.preloader = false
    },
    MxHeight() {
      this.lprs_height = window.innerHeight - (document.getElementById('lprs').getBoundingClientRect().top + 20)
      this.tbsh_height = window.innerHeight - (document.getElementById('tbsh').getBoundingClientRect().top + 20)
    },
  }
}
</script>
